@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ceviche+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap);
.app {
  background-color: whitesmoke;
}

html {
  background-color: whitesmoke;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.landing-page-cnt {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  overflow-x: hidden;
}

.landing-page-cnt .landingpage-bg {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  background: url(/static/media/landingpagefinish1.7476b0c6.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: 1;
}

@media screen and (max-width: 1000px) {
  .landing-page-cnt .landingpage-bg {
    background: url(/static/media/landingpagemedium.ef85992e.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.landing-page-cnt .col-1 {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  display: flex;
  flex-direction: column;
  padding-left: 120px;
}

@media screen and (max-width: 1300px) {
  .landing-page-cnt .col-1 {
    top: 0%;
    background: none;
    height: 100%;
    align-items: center;
    padding-left: 0;
  }
}

.landing-page-cnt .col-1 .welcome-h1 {
  position: relative;
  top: 20%;
  font-size: 50px;
  font-weight: 100;
  width: 100%;
  background: none;
  -webkit-animation: fade-down 2s;
          animation: fade-down 2s;
  font-family: "Anton", sans-serif;
}

@media screen and (max-width: 1300px) {
  .landing-page-cnt .col-1 .welcome-h1 {
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0%;
    font-size: 45px;
    padding: 0px 50px;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .landing-page-cnt .col-1 .welcome-h1 {
    font-size: 35px;
    padding: 0px 0px;
  }
}

@media screen and (max-width: 500px) {
  .landing-page-cnt .col-1 .welcome-h1 {
    font-size: 25px;
    padding: 0px 0px;
    top: 15%;
  }
}

.landing-page-cnt .col-1 .link-container {
  position: relative;
  top: 20%;
  display: flex;
  background: none;
  width: 450px;
  height: 30%;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
}

@media screen and (max-width: 900px) {
  .landing-page-cnt .col-1 .link-container {
    top: 10%;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
  }
}

@media screen and (max-width: 450px) {
  .landing-page-cnt .col-1 .link-container {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    top: 10%;
  }
}

.landing-page-cnt .col-1 .link-container .link-1 {
  position: relative;
  margin-right: 20px;
  -webkit-animation: fade-up 2s;
          animation: fade-up 2s;
  font-family: "Anton", sans-serif;
}

.landing-page-cnt .col-1 .link-container .link-1 .button-1 {
  background: transparent;
  border: 1px solid #d43a57;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 40px;
  color: #d43a57;
  transition: 0.5s;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.082);
  text-transform: uppercase;
  font-weight: 500;
  width: 100%;
}

@media screen and (max-width: 450px) {
  .landing-page-cnt .col-1 .link-container .link-1 .button-1 {
    font-size: 10px;
  }
}

.landing-page-cnt .col-1 .link-container .link-1 .button-1:hover {
  text-decoration: none;
  border: 1px solid #862436;
  color: #862436;
}

.landing-page-cnt .col-1 .link-container .link-1 .about-btn {
  position: relative;
  border: 1px solid #0da0f2;
  color: #0da0f2;
  background: transparent;
  transition: 0.5s;
  cursor: pointer;
}

.landing-page-cnt .col-1 .link-container .link-1 .about-btn:hover {
  border: 1px solid #0882c4;
  color: #0882c4;
}

.about-section {
  position: relative;
  height: 100%;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .about-section {
    align-items: center;
  }
}

.about-section .topwave {
  position: absolute;
  top: 0px;
  left: 0%;
  height: 100%;
  width: 100%;
  background: url(/static/media/topwave.3cc19b7d.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

@media screen and (max-width: 1000px) {
  .about-section .topwave {
    background: url(/static/media/topwavemedium.4d6f0b74.png);
    background-position: top;
    background-size: auto;
    background-repeat: no-repeat;
  }
}

.about-section .about-row {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 250px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1300px) {
  .about-section .about-row {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1000px) {
  .about-section .about-row {
    padding-top: 150px;
  }
}

.about-section .about-row .info-col {
  position: relative;
  height: 100%;
  width: 24%;
  margin-bottom: 100px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .about-section .about-row .info-col {
    width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .about-section .about-row .info-col {
    width: 90%;
  }
}

@media screen and (max-width: 1300px) {
  .about-section .about-row .info-col {
    margin-bottom: 200px;
  }
}

.about-section .about-row .info-col .inner-col {
  position: relative;
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.192);
  border-radius: 10px;
  transition: 0.5s;
}

@media screen and (max-width: 1300px) {
  .about-section .about-row .info-col .inner-col {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.274);
  }
}

@media screen and (max-width: 500px) {
  .about-section .about-row .info-col .inner-col {
    height: 400px;
  }
}

.about-section .about-row .info-col .inner-col:hover {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.192);
}

.about-section .about-row .info-col .inner-col .col-img {
  position: absolute;
  height: 250px;
  top: -30%;
  transition: 0.5s;
}

.about-section .about-row .info-col .inner-col .col-img:hover {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.about-section .about-row .info-col .inner-col .info-h3 {
  position: relative;
  top: 35%;
  text-transform: uppercase;
  font-weight: 900;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.about-section .about-row .info-col .inner-col .info-p {
  position: relative;
  top: 40%;
  text-align: center;
  color: black;
  font-weight: 600;
  font-size: 20px;
  padding: 0px 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width: 500px) {
  .about-section .about-row .info-col .inner-col .info-p {
    font-size: 18px;
    top: 35%;
  }
}

@media screen and (max-width: 400px) {
  .about-section .about-row .info-col .inner-col .info-p {
    font-size: 15px;
    top: 35%;
  }
}

.about-section .mailing-row {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow-x: hidden;
  padding: 100px 0px;
}

.about-section .mailing-row .stars,
.about-section .mailing-row .star2,
.about-section .mailing-row .star3,
.about-section .mailing-row .star4,
.about-section .mailing-row .star5,
.about-section .mailing-row .star6 {
  position: absolute;
  height: 150px;
  width: 150px;
}

.about-section .mailing-row .stars {
  top: 16%;
  -webkit-animation: left2right 7s infinite linear;
          animation: left2right 7s infinite linear;
}

.about-section .mailing-row .star2 {
  top: 65%;
  -webkit-animation: left2right 8s infinite linear;
          animation: left2right 8s infinite linear;
}

.about-section .mailing-row .star3 {
  top: 25%;
  -webkit-animation: left2right 6s infinite linear;
          animation: left2right 6s infinite linear;
}

.about-section .mailing-row .star4 {
  top: 5%;
  -webkit-animation: left2right 10s infinite linear;
          animation: left2right 10s infinite linear;
}

.about-section .mailing-row .star5 {
  top: 55%;
  -webkit-animation: left2right 5s infinite linear;
          animation: left2right 5s infinite linear;
}

.about-section .mailing-row .star6 {
  top: 75%;
  -webkit-animation: left2right 7.4s infinite linear;
          animation: left2right 7.4s infinite linear;
}

.about-section .mailing-row .subscribe-col {
  position: relative;
  height: 600px;
  width: 50%;
  background: rgba(255, 255, 255, 0.486);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.199);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .about-section .mailing-row .subscribe-col {
    justify-content: center;
    height: 500px;
    width: 100%;
  }
}

.about-section .mailing-row .subscribe-col .info-mail {
  position: relative;
  height: 100%;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1300px) {
  .about-section .mailing-row .subscribe-col .info-mail {
    align-items: center;
  }
}

.about-section .mailing-row .subscribe-col .info-mail .newsletter-h1 {
  color: #d43a57;
  text-transform: uppercase;
  font-weight: 900;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.171);
  font-size: 50px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width: 1700px) {
  .about-section .mailing-row .subscribe-col .info-mail .newsletter-h1 {
    font-size: 40px;
    text-align: center;
  }
}

.about-section .mailing-row .subscribe-col .info-mail .newsletter-p {
  color: #0da0f2;
  font-size: 20px;
  padding: 20px 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width: 1700px) {
  .about-section .mailing-row .subscribe-col .info-mail .newsletter-p {
    padding: 20px 20px;
    text-align: center;
  }
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form {
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
}

@media screen and (max-width: 1300px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form {
    justify-content: center;
  }
}

@media screen and (max-width: 1050px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form {
    flex-direction: column;
  }
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email {
  position: relative;
  width: 60%;
  margin-right: 20px;
  height: 55px;
  background: none;
  color: #0da0f2;
  text-transform: uppercase;
  font-weight: 900;
  padding-left: 20px;
  border: 1px solid #0da0f2;
  border-radius: 10px;
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email::-webkit-input-placeholder {
  color: #d43a57;
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email::placeholder {
  color: #d43a57;
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email:focus {
  border: 1px solid #d43a57;
  outline: none;
}

@media screen and (max-width: 1050px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email {
    margin-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form .subscribe-email {
    margin-right: 0px;
    padding-left: 0px;
    text-align: center;
    font-size: 15px;
    width: 100%;
  }
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .submit-button {
  position: relative;
  width: 20%;
  height: 50px;
  background: none;
  border-radius: 10px;
  color: #0da0f2;
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
  transition: 0.5s;
}

.about-section .mailing-row .subscribe-col .info-mail .subscribe-form .submit-button:hover {
  border-color: #08a2f4;
  color: #e63946;
}

@media screen and (max-width: 1050px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form .submit-button {
    width: 40%;
    top: 20px;
    border: 2px solid black;
  }
}

@media screen and (max-width: 500px) {
  .about-section .mailing-row .subscribe-col .info-mail .subscribe-form .submit-button {
    cxcwidth: 100%;
  }
}

.scroll-up-btn-ctn {
  position: fixed;
  width: 60px;
  right: 10px;
  height: 60px;
  bottom: 10px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  color: red;
  -webkit-animation: fadein 1s;
          animation: fadein 1s;
}

.scroll-up-btn-ctn .arrow-up {
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.scroll-up-btn-ctn .arrow-up:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  color: #0da0f2;
}

.scroll-up-btn-hidden {
  display: none;
  transition: 0.5;
  -webkit-animation: fadeout 1s;
          animation: fadeout 1s;
}

#bluestar,
#star,
#redstar {
  -webkit-animation: left 3s infinite linear;
          animation: left 3s infinite linear;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

#circle {
  -webkit-animation: tilt 5s infinite linear;
          animation: tilt 5s infinite linear;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
}

#letter {
  -webkit-animation: downtoup 10s infinite ease-in-out;
          animation: downtoup 10s infinite ease-in-out;
}

@-webkit-keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes downtoup {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes downtoup {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes tilt {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes tilt {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes spinleft {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes spinleft {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@-webkit-keyframes left {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@keyframes left {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
}

@-webkit-keyframes left2right {
  0% {
    left: -300px;
  }
  100% {
    left: 2500px;
  }
}

@keyframes left2right {
  0% {
    left: -300px;
  }
  100% {
    left: 2500px;
  }
}
/*# sourceMappingURL=home.css.map */
/*           FONTS                 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  background: whitesmoke;
  background-color: whitesmoke;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  transition: 0.5s;
}

@media screen and (max-width: 1000px) {
  .nav {
    background-color: whitesmoke;
    justify-content: center;
  }
}

@media screen and (max-height: 840px) {
  .nav {
    background-color: white;
  }
}

.nav .mobile-theme-btn {
  display: none;
}

@media screen and (max-width: 1000px) {
  .nav .mobile-theme-btn {
    display: flex;
    align-items: center;
    height: 80px;
    background: none;
    position: absolute;
    left: 20px;
  }
}

.nav .brand {
  position: absolute;
  color: black;
  font-weight: 700;
  padding-left: 80px;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  font-family: sans-serif;
  z-index: 1;
}

.nav .brand:hover {
  color: white;
  text-decoration: none;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

@media screen and (max-width: 1000px) {
  .nav .brand {
    padding-left: 0px;
  }
}

.nav .logo-img {
  position: relative;
  height: 150px;
  width: 150px;
}

nav.active {
  background-color: white;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.514);
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  position: absolute;
  display: flex;
  list-style: none;
  text-align: center;
  background: none;
  width: 795px;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  background: none;
}

.nav-item.themeButton {
  position: absolute;
  right: 30px;
}

@media screen and (max-width: 1000px) {
  .nav-item.themeButton {
    display: none;
  }
}

.nav-links {
  font-family: sans-serif;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 2px;
  text-decoration: none;
  padding: 5px 20px;
  transition: 0.5s;
}

.nav-links-white {
  color: white;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 15px;
  letter-spacing: 2px;
  text-decoration: none;
  padding: 5px 20px;
  transition: 0.5s;
}

.nav-links-white:hover {
  text-decoration: none;
  color: black;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.nav-links:hover {
  background-color: none;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-menu.active {
    background: whitesmoke;
    left: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-menu.activeDark {
    background: #272a2d;
    left: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: none;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: black;
    font-size: 2rem;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #ffffff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
  button {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: whitesmoke;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f9ef0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dd1a22;
}
/*# sourceMappingURL=nav.css.map */
.footer-container {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px 120px;
}

@media screen and (max-width: 1630px) {
  .footer-container {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 1500px) {
  .footer-container {
    padding: 20px 20px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1430px) {
  .footer-container {
    padding: 20px 0px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container {
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container {
    flex-direction: column;
    height: 100%;
    padding: 100px 0px;
    justify-content: center;
    align-items: center;
  }
}

.footer-container .footerheader {
  position: relative;
  width: 15%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.footer-container .footerheader .footer-logo {
  position: absolute;
  height: 300px;
  left: -30px;
}

@media screen and (max-width: 1250px) {
  .footer-container .footerheader .footer-logo {
    left: 25%;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footerheader .footer-logo {
    left: 0;
    position: relative;
    height: 250px;
  }
}

@media screen and (max-width: 1430px) {
  .footer-container .footerheader {
    width: 10%;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footerheader {
    width: 50%;
    height: 200px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container .footerheader {
    width: 100%;
    height: 200px;
    margin-right: 0px;
  }
}

.footer-container .footer-about-section {
  position: relative;
  width: 20%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  margin-right: 30px;
}

@media screen and (max-width: 1430px) {
  .footer-container .footer-about-section {
    margin-right: 0px;
    align-items: center;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-about-section {
    width: 50%;
    height: 200px;
    margin-right: 0px;
    padding: 0px 50px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container .footer-about-section {
    width: 80%;
    height: 200px;
    margin-right: 0px;
    padding: 0px 0px;
  }
}

.footer-container .footer-about-section h1 {
  color: #08a2f4;
  font-size: 25px;
  font-family: "Raleway", sans-serif;
}

.footer-container .footer-about-section p {
  text-align: left;
  color: white;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 1430px) {
  .footer-container .footer-about-section p {
    text-align: center;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-about-section p {
    font-size: 13px;
  }
}

.footer-container .footer-links {
  position: relative;
  width: 15%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 50px;
  padding-top: 10px;
  margin-right: 30px;
}

@media screen and (max-width: 1430px) {
  .footer-container .footer-links {
    margin-right: 0px;
    padding-left: 0px;
    width: 12%;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-links {
    align-items: center;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-links {
    width: 50%;
    height: 200px;
    margin-right: 0px;
  }
}

.footer-container .footer-links h1 {
  color: #08a2f4;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-links h1 {
    font-size: 18px;
  }
}

.footer-container .footer-links .link {
  color: white;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
}

.footer-container .footer-links .link:hover {
  text-decoration: none;
  color: red;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.footer-container .footer-subscribe-email {
  position: relative;
  width: 20%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  margin-right: 30px;
}

@media screen and (max-width: 1430px) {
  .footer-container .footer-subscribe-email {
    width: 30%;
  }
}

@media screen and (max-width: 1250px) {
  .footer-container .footer-subscribe-email {
    width: 50%;
    height: 200px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container .footer-subscribe-email {
    width: 90%;
    height: 200px;
    margin-right: 0px;
  }
}

.footer-container .footer-subscribe-email .sub-content {
  position: relative;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.footer-container .footer-subscribe-email .sub-content h1 {
  color: white;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}

.footer-container .footer-subscribe-email .sub-content p {
  color: white;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.footer-container .footer-subscribe-email .sub-inputs {
  position: relative;
  width: 100%;
  height: 30%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
}

.footer-container .footer-subscribe-email .sub-inputs .textField {
  height: 50px !important;
  width: 70%;
  height: 50px;
  margin-right: 10px;
}

.footer-container .footer-subscribe-email .sub-inputs .sub-button {
  color: white;
  border-color: #e63946;
}

.footer-container .footer-subscribe-email .sub-inputs .sub-button:hover {
  border-color: #08a2f4;
  color: #e63946;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}
/*# sourceMappingURL=footer.css.map */
.gallery-cont {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-top: 100px;
  transition: 0.45s;
}

@media screen and (max-height: 830px) {
  .gallery-cont {
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 900px) {
  .gallery-cont {
    height: 100%;
    background-position: center;
    background-size: cover;
    align-items: center;
  }
}

.gallery-cont .row-1 {
  position: relative;
  height: 100%;
  width: 100%;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .gallery-cont .row-1 {
    top: 20%;
  }
}

@media screen and (max-width: 500px) {
  .gallery-cont .row-1 {
    width: 100%;
  }
}

.gallery-cont .row-1 .sales-container {
  position: relative;
  width: 80%;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.525);
  transition: 0.2s ease-in;
  padding-bottom: 20px;
}

@media screen and (max-width: 1300px) {
  .gallery-cont .row-1 .sales-container {
    width: 95%;
  }
}

.gallery-cont .row-1 .sales-container .sales-h1-container {
  position: relative;
  height: 80px;
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.gallery-cont .row-1 .sales-container .sales-h1-container .sale-h1 {
  position: relative;
  top: 5%;
  font-size: 40px;
  font-family: "Anton", sans-serif;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .gallery-cont .row-1 .sales-container .sales-h1-container .sale-h1 {
    position: absolute;
    font-size: 40px;
    top: 10%;
    z-index: 5;
    background: none;
    height: 100px;
  }
}

.gallery-cont .row-1 .sales-container .description-container {
  position: relative;
  width: 85%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-cont .row-1 .sales-container .description-container .textfield-description-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.gallery-cont .row-1 .sales-container .description-container .edit-description-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.gallery-cont .row-1 .sales-container .description-container .edit-description-container .edit-button {
  position: absolute;
  z-index: 30;
  top: -15px;
  right: 20px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #08a2f4;
  cursor: pointer;
}

.gallery-cont .row-1 .sales-container .description-container .edit-description-container .description-p {
  position: relative;
  width: 100%;
  top: 0%;
  font-size: 20px;
  padding: 5px 0px;
  overflow-y: auto;
  font-weight: 600;
  border-radius: 0px;
}

@media screen and (max-width: 1300px) {
  .gallery-cont .row-1 .sales-container .description-container .edit-description-container .description-p {
    font-size: 15px;
    padding: 10px;
    width: 100%;
    align-items: flex-start;
  }
}

.gallery-cont .row-1 .sticky {
  position: fixed;
  top: 90px;
  z-index: 30;
  width: 75%;
  transition: 0.5s ease;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.164);
}

@media screen and (max-width: 900px) {
  .gallery-cont .row-1 .sticky {
    width: 90%;
  }
}

.gallery-cont .row-1 .photo-container {
  position: relative;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.281);
  top: 0%;
  padding: 10px 10px;
  margin-bottom: 10 0px;
  width: 80%;
  height: 800px;
  border-radius: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  transition: 0.45s;
}

@media screen and (max-width: 1300px) {
  .gallery-cont .row-1 .photo-container {
    height: 800px;
    width: 95%;
    margin-top: 0px;
  }
}

@media screen and (max-width: 790px) {
  .gallery-cont .row-1 .photo-container {
    height: 510px;
  }
}

.gallery-cont .row-1 .photo-container .loader {
  position: relative;
  height: 327px;
  width: 350px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

@media screen and (max-width: 430px) {
  .gallery-cont .row-1 .photo-container .loader {
    width: 300px;
  }
}

.gallery-cont .row-1 .photo-container .loader p {
  font-size: 20;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 5px;
}

.gallery-cont .row-1 .photo-container .end-message {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.gallery-cont .row-1 .photo-container .item-photos {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.gallery-cont .row-1 .photo-container .item-photos:hover {
  cursor: pointer;
}

.gallery-cont .row-1 .photo-container .item-card-container {
  position: relative;
  width: 350px;
  margin: 0px 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.096);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  transition: 0.45s;
}

@media screen and (max-width: 430px) {
  .gallery-cont .row-1 .photo-container .item-card-container {
    width: 300px;
  }
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal {
  position: absolute;
  width: 90%;
  top: 15px;
  height: 220px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.096);
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .modal-top-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .modal-top-container .close-item-modal {
  position: absolute;
  right: 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .modal-top-container h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  padding: 10px 0px;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .seperator {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: #818181;
  margin-bottom: 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .submit-form {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0px;
  align-items: center;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .submit-form .submit-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .edit-item-modal .edit-item-modal-icon {
  position: absolute;
  bottom: -45px;
  font-size: 80px;
  left: -15px;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-card-img-container {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.45s;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-card-img-container .img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  transition: 0.45s;
}

.gallery-cont .row-1 .photo-container .item-card-container .seperator {
  position: relative;
  height: 2px;
  width: 100%;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-card-badges {
  position: relative;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  padding: 0px 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-card-badges .title-badge {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  border-radius: 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-card-badges h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
}

.gallery-cont .row-1 .photo-container .item-card-container .item-desc-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.gallery-cont .row-1 .photo-container .item-card-container .bottom-info-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  justify-content: flex-start;
}

.gallery-cont .row-1 .photo-container .item-card-container .bottom-info-container .title-badge {
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  border-radius: 5px;
}

.gallery-cont .row-1 .photo-container .item-card-container .bottom-info-container .img-settings-container {
  position: absolute;
  bottom: 0;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}

.gallery-cont .row-1 .photo-container::-webkit-scrollbar {
  position: absolute;
  width: 8px;
  right: 10px;
}

.gallery-cont .row-1 .photo-container::-webkit-scrollbar-track {
  background: transparent;
}

.gallery-cont .row-1 .photo-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.gallery-cont .row-1 .photomodal {
  top: 0px;
  left: 0px;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  -webkit-animation: fadeintoview 0.5s;
          animation: fadeintoview 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.082);
  cursor: pointer;
}

.gallery-cont .row-1 .photomodal .photo-modal-container {
  position: relative;
  height: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .gallery-cont .row-1 .photomodal .photo-modal-container {
    width: 95%;
    height: 500px;
  }
}

@media screen and (max-width: 800px) {
  .gallery-cont .row-1 .photomodal .photo-modal-container {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .gallery-cont .row-1 .photomodal .photo-modal-container {
    width: 95%;
  }
}

.gallery-cont .row-1 .photomodal .photo-modal-container .next-photo-container,
.gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.6s;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .next-photo-container .button,
.gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container .button {
  color: #d43a568e;
  transition: 0.6s;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .next-photo-container .button:hover,
.gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container .button:hover {
  color: #d43a57;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .next-photo-container:hover,
.gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container:hover {
  background: rgba(0, 0, 0, 0.212);
}

@media screen and (max-width: 500px) {
  .gallery-cont .row-1 .photomodal .photo-modal-container .next-photo-container:hover,
  .gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container:hover {
    background: none;
  }
}

.gallery-cont .row-1 .photomodal .photo-modal-container .prev-photo-container {
  left: 0px;
  justify-content: flex-start;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .modal-info-container {
  position: absolute;
  bottom: 0;
  z-index: 200;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  padding: 25px 20px;
  background: #020024;
  background: #020024;
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, black 100%);
  transition: 0.25s;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: auto;
}

@media screen and (max-width: 500px) {
  .gallery-cont .row-1 .photomodal .photo-modal-container .modal-info-container {
    height: 50px;
    background: none;
  }
}

.gallery-cont .row-1 .photomodal .photo-modal-container .modal-info-container .title-badge {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 10px;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .enlarged-photo-container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f5f5f5;
}

.gallery-cont .row-1 .photomodal .photo-modal-container .enlarged-photo-container .enlarged-photo {
  position: relative;
  height: 100%;
  background: none;
  transition: 0.5s;
  border-radius: 20px;
  object-fit: contain;
  background-size: auto;
  background-position: center;
}

.gallery-cont .row-1 .hidden-photomodal {
  display: none;
}

@-webkit-keyframes fadeintoview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeintoview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=sales.css.map */
/* No CSS *//*# sourceMappingURL=itemcard.css.map */
.admin-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(/static/media/gradientBackground.7a1e5eb4.png);
  background-color: #faebd7 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-container .controls-container {
  position: relative;
  height: 70%;
  width: 70%;
  background: rgba(35, 35, 35, 0.6);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  border-radius: 10px;
}

.admin-container .controls-container .tab-container {
  position: relative;
  height: 10%;
  width: 100%;
  z-index: 100;
}

.admin-container .controls-container .tab-content-container {
  position: relative;
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container {
  position: relative;
  height: 100%;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .header-container {
  position: relative;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .header-container h1 {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .dropzone-container {
  position: relative;
  height: 10%;
  width: 90%;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='8' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  border-radius: 5px;
  z-index: 100;
  cursor: pointer;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .uploaded-pictures {
  position: relative;
  height: 60%;
  width: 90%;
  background: #1f1f1f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .uploaded-pictures .images {
  position: relative;
  height: 150px;
  width: 50%;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .photo-buttons {
  position: relative;
  width: 90%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .textarea {
  position: relative;
  height: 90%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
}

.admin-container .controls-container .tab-content-container .description-container .upload-container .textarea .buttons-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-container .controls-container .tab-content-container .description-container .mid-container {
  height: 90%;
  background-color: rgba(0, 0, 0, 0.288);
  border-radius: 20px;
  width: 5px;
}

.admin-container .controls-container .tab-content-container .description-container .result-container {
  position: relative;
  height: 100%;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-header-container {
  position: relative;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-header-container .result-h1 {
  color: white;
  font-size: 30px;
  text-transform: uppercase;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-photos {
  position: relative;
  height: 70%;
  width: 90%;
  background: #1f1f1f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-photos .images {
  position: relative;
  height: 150px;
  width: 50%;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .loading-container {
  position: relative;
  height: 70%;
  width: 90%;
  background: #1f1f1f;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .loading-container p {
  color: white;
  font-size: 30;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-box {
  position: relative;
  width: 90%;
  height: 65%;
  border-radius: 3px;
  padding: 16px 14px;
  background-color: #1f1f1f;
  border: 1px solid white;
}

.admin-container .controls-container .tab-content-container .description-container .result-container .result-box p {
  color: white;
}
/*# sourceMappingURL=admin.css.map */
.login-container {
  position: relative;
  height: 100vh;
  background-color: #053b5a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .top-logo {
  position: absolute;
  height: 300px;
  width: 300px;
  top: 10%;
  z-index: 13;
}

.login-container .login-form-cnt {
  position: relative;
  height: 440px;
  width: 430px;
  border-radius: 10px;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.308);
  transition: 0.5s;
  z-index: 10;
}

.login-container .login-form-cnt:hover {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.349);
}

.login-container .login-form-cnt .login-h1 {
  position: absolute;
  top: 10%;
  font-size: 30px;
  font-family: inherit;
  width: 100%;
  text-align: center;
  color: #000000;
  font-weight: 100;
  z-index: 4;
}

.login-container .login-form-cnt .form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.login-container .login-form-cnt .form .username-input,
.login-container .login-form-cnt .form .password-input {
  width: 80%;
  height: 50px;
  background: whitesmoke;
  border: none;
  border-bottom: 3px solid red;
  outline: 0;
}

.login-container .login-form-cnt .form .username-input::-webkit-input-placeholder,
.login-container .login-form-cnt .form .password-input::-webkit-input-placeholder {
  color: black;
  text-transform: uppercase;
}

.login-container .login-form-cnt .form .username-input::-webkit-input-placeholder, .login-container .login-form-cnt .form .password-input::-webkit-input-placeholder {
  color: black;
  text-transform: uppercase;
}

.login-container .login-form-cnt .form .username-input::placeholder,
.login-container .login-form-cnt .form .password-input::placeholder {
  color: black;
  text-transform: uppercase;
}

.login-container .login-form-cnt .form .username-input:focus,
.login-container .login-form-cnt .form .password-input:focus {
  outline: none;
}

.login-container .login-form-cnt .form .login-button {
  position: relative;
  width: 80%;
  background: none;
  height: 45px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-radius: 10px;
  background-color: #e60505;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  transition: 0.5s;
}

.login-container .login-background-container {
  position: absolute;
  height: 447px;
  width: 437px;
  border-radius: 11px;
  z-index: 9;
  background: linear-gradient(45deg, #00a3ff, #e60505, #76e6ff, #00aef0, #d41010);
  background-size: 400%;
  -webkit-animation: animate 10s linear infinite;
          animation: animate 10s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*# sourceMappingURL=login.css.map */
.contact-container {
  position: relative;
  height: 100vh;
  background: #001724;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-container .alert-box {
  position: absolute;
  background: whitesmoke;
  border-radius: 10px;
  top: 20px;
  height: 100px;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.397);
  transition: 0.5s;
  z-index: 1000;
}

@media screen and (max-width: 1000px) {
  .contact-container .alert-box {
    top: 100px;
  }
}

.contact-container .alert-box .success-line {
  position: absolute;
  height: 85%;
  width: 5px;
  left: 8px;
  border-radius: 10px;
  background: #52b788;
}

.contact-container .alert-box .success-icon {
  position: absolute;
  left: 30px;
  font-size: 60px;
  padding: 10px;
  color: #b7e4c7;
  background: #2d6a4f;
  border-radius: 50%;
}

.contact-container .alert-box .info-section {
  position: relative;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
  padding-top: 10px;
}

.contact-container .alert-box .info-section h1 {
  font-size: 30px;
  margin-bottom: 0px;
  color: #40916c;
}

.contact-container .alert-box .info-section p {
  font-size: 12px;
  color: #1b4332;
  width: 100%;
}

.contact-container .alert-box .close-window {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: #2d6a4f;
  color: #d8f3dc;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.contact-container .alert-box .close-window:hover {
  color: #2d6a4f;
  background: #d8f3dc;
}

.contact-container .small {
  top: 200px;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  z-index: 0;
}

.contact-container .hidden {
  opacity: 0;
  top: -200px;
}

.contact-container .contact-row {
  position: relative;
  height: 600px;
  width: 55%;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media screen and (max-width: 1200px) {
  .contact-container .contact-row {
    width: 90%;
  }
}

@media screen and (max-width: 1000px) {
  .contact-container .contact-row {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .contact-container .contact-row {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contact-container .contact-row {
    width: 100%;
  }
}

@media screen and (max-height: 1200px) {
  .contact-container .contact-row {
    height: 600px;
  }
}

@media screen and (max-height: 650px) {
  .contact-container .contact-row {
    height: 80%;
    width: 95%;
  }
}

.contact-container .contact-row .contact-info-col {
  position: relative;
  height: 100%;
  width: 40%;
  border-radius: 10px;
  background: url(/static/media/waves.d2cb90c5.png);
  background-size: cover;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.315);
}

@media screen and (max-width: 800px) {
  .contact-container .contact-row .contact-info-col {
    display: none;
  }
}

.contact-container .contact-row .contact-info-col .logo-container {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
}

.contact-container .contact-row .contact-info-col .logo-container .logo-img {
  position: absolute;
  width: 90%;
  right: 30px;
}

.contact-container .contact-row .contact-info-col h1 {
  position: relative;
  color: white;
  top: 0px;
  text-transform: uppercase;
  font-size: 30px;
  padding: 0px 10px;
  width: 100%;
  z-index: 1;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.384);
}

@media screen and (max-width: 900px) {
  .contact-container .contact-row .contact-info-col h1 {
    padding: 0px 0px;
    text-align: center;
  }
}

.contact-container .contact-row .contact-info-col p {
  position: relative;
  width: 100%;
  padding: 0px 10px;
  color: white;
  z-index: 1;
}

@media screen and (max-width: 900px) {
  .contact-container .contact-row .contact-info-col p {
    padding: 0px 0px;
    font-size: 15px;
    text-align: left;
  }
}

.contact-container .contact-row .contact-info-col .contact-list {
  position: relative;
  width: 100%;
  height: 30%;
  background: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 1;
}

.contact-container .contact-row .contact-info-col .contact-list .list-item {
  list-style: none;
  font-size: 20px;
  padding: 10px 10px;
  color: white;
  font-weight: 600;
  text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.555);
}

@media screen and (max-width: 900px) {
  .contact-container .contact-row .contact-info-col .contact-list .list-item {
    padding: 10px 0px;
    font-size: 17px;
  }
}

.contact-container .contact-row .contact-info-col .stars {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 90%;
  background: none;
}

.contact-container .contact-row .contact-info-col .stars .star1,
.contact-container .contact-row .contact-info-col .stars .star2,
.contact-container .contact-row .contact-info-col .stars .star3,
.contact-container .contact-row .contact-info-col .stars .star4,
.contact-container .contact-row .contact-info-col .stars .star5 {
  position: absolute;
  bottom: -20%;
}

.contact-container .contact-row .contact-info-col .stars .star1 {
  -webkit-animation: down2up 5s infinite linear;
          animation: down2up 5s infinite linear;
}

.contact-container .contact-row .contact-info-col .stars .star2 {
  left: 50%;
  -webkit-animation: down2up 3.5s infinite linear;
          animation: down2up 3.5s infinite linear;
}

.contact-container .contact-row .contact-info-col .stars .star3 {
  left: 30%;
  -webkit-animation: down2up 5.5s infinite linear;
          animation: down2up 5.5s infinite linear;
}

.contact-container .contact-row .contact-info-col .stars .star4 {
  left: 80%;
  -webkit-animation: down2up 6s infinite linear;
          animation: down2up 6s infinite linear;
}

.contact-container .contact-row .contact-info-col .stars .star5 {
  left: 65%;
  -webkit-animation: down2up 6.6s infinite linear;
          animation: down2up 6.6s infinite linear;
}

.contact-container .contact-row .contact-form-col {
  position: relative;
  height: 100%;
  width: 60%;
  background: whitesmoke;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.315);
  padding: 0px;
}

@media screen and (max-width: 1200px) {
  .contact-container .contact-row .contact-form-col {
    border-radius: 20px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .contact-container .contact-row .contact-form-col {
    width: 100%;
  }
}

.contact-container .contact-row .contact-form-col .logo {
  position: absolute;
  z-index: 0;
  top: -43%;
  transition: 0.5s;
}

.contact-container .contact-row .contact-form-col .logo:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

@media screen and (max-width: 1200px) {
  .contact-container .contact-row .contact-form-col .logo {
    height: 400px;
    top: -38%;
  }
}

@media screen and (max-width: 550px) {
  .contact-container .contact-row .contact-form-col .logo {
    height: 300px;
    width: 300px;
    top: -30%;
  }
}

@media screen and (max-height: 700px) {
  .contact-container .contact-row .contact-form-col .logo {
    display: none;
  }
}

.contact-container .contact-row .contact-form-col .contact-us-h1 {
  position: absolute;
  top: 0px;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-size: 60px;
  color: #1d3557;
}

@media screen and (max-width: 450px) {
  .contact-container .contact-row .contact-form-col .contact-us-h1 {
    font-size: 50px;
    top: 30px;
  }
}

@media screen and (max-width: 380px) {
  .contact-container .contact-row .contact-form-col .contact-us-h1 {
    font-size: 40px;
    top: 30px;
  }
}

@media screen and (max-width: 300px) {
  .contact-container .contact-row .contact-form-col .contact-us-h1 {
    font-size: 35px;
    top: 30px;
  }
}

.contact-container .contact-row .contact-form-col form {
  position: relative;
  height: 90%;
  width: 90%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 50px;
}

.contact-container .contact-row .contact-form-col form .name-inputs {
  position: relative;
  background: none;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contact-container .contact-row .contact-form-col form .name-inputs .name-input {
  position: relative;
  border: 0px;
  border-bottom: 5px solid black;
  height: 50px;
  width: 45%;
  padding-left: 10px;
  color: black;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  background: linear-gradient(90deg, #00a3ff, #d41010) center bottom/0 2px no-repeat, linear-gradient(90deg, black, black) left bottom/100% 2px no-repeat, linear-gradient(90deg, white, white) left bottom/100% 100% no-repeat;
  transition: background-size 0.3s ease;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  outline: none;
  font-size: 15px;
}

.contact-container .contact-row .contact-form-col form .name-inputs .name-input:valid, .contact-container .contact-row .contact-form-col form .name-inputs .name-input:focus {
  background-size: 100% 2px, 100% 2px, 100%;
}

.contact-container .contact-row .contact-form-col form .name-inputs .name-input:focus {
  outline: none;
}

.contact-container .contact-row .contact-form-col form .text-box-container {
  position: relative;
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container .contact-row .contact-form-col form .text-box-container .textbox {
  position: relative;
  height: 90%;
  width: 95%;
  border: 0px solid black;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.089);
  padding: 10px 10px;
  border-radius: 10px;
}

.contact-container .contact-row .contact-form-col form .text-box-container .textbox:focus {
  outline: none;
}

@media screen and (max-height: 650px) {
  .contact-container .contact-row .contact-form-col form .text-box-container .textbox {
    height: 100px;
  }
}

.contact-container .contact-row .contact-form-col form .submit-message {
  position: absolute;
  bottom: 10px;
  right: 12px;
  padding: 10px 50px;
  border-radius: 10px;
  background: #1d3557;
  color: #a8dadc;
  text-transform: uppercase;
  border: none;
  font-family: "Roboto", sans-serif;
  transition: 0.5s;
}

.contact-container .contact-row .contact-form-col form .submit-message:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  background: #a8dadc;
  color: #1d3557;
}

@media screen and (max-width: 600px) {
  .contact-container .contact-row .contact-form-col form .submit-message {
    width: 100%;
    right: 0px;
  }
}

@media screen and (max-height: 1000px) {
  .contact-container .contact-row .contact-form-col form .submit-message {
    padding: 10px 50px;
    height: 50px;
    top: 90%;
  }
  .contact-container .contact-row .contact-form-col form .submit-message:hover {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@media screen and (max-height: 650px) {
  .contact-container .contact-row .contact-form-col form .submit-message {
    bottom: 0%;
    background: black;
    color: white;
  }
}

@-webkit-keyframes down2up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
}

@keyframes down2up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*# sourceMappingURL=contact.css.map */
